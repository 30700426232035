.App {
  text-align: center;
}

.form-wrapper {
  position: relative;
  overflow: hidden;
}

.wrapper {
  padding-top: 3.2rem;
}

.navbar-brand {
  margin-right: 0;
}
