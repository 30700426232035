#hero-couriers {
  position: relative;
  width: 100%;
}
#hero-couriers::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  background: linear-gradient(rgb(255, 250, 235), rgb(255, 255, 255));
}
