.title {
  margin-top: 1rem;
}

#policy li {
  display: list-item;
}

strong {
  font-size: 0.9rem;
}

p {
  letter-spacing: -0.5px;
  color: #4a4a4a;
  line-height: 1.2rem;
  font-size: 1rem;
  display: inline-block;
}

p a {
  color: #363636;
  cursor: pointer;
  padding: 5px;
  text-decoration: underline;
}
