/** Download Section **/

.download-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-btn {
  width: 300px;
  height: 54px;
  border-radius: 10px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: 0.3s;
}
.download-btn:hover {
  background-color: white;
}
.download-btn > span {
  font-family: 'OscarFM';
  font-size: 1.375rem;
}
