/** Carousel (Feedbacks) Section **/

.card-0 {
  position: relative;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.carousel-indicators {
  margin: -10px;
}
.carousel-indicators li {
  cursor: pointer;
  border-radius: 50% !important;
  width: 10px;
  height: 10px;
  background-color: #fcfcfc;
  border: 1px solid #707070;
}

.carousel-indicators .active {
  background-color: #ffc625;
  border: none;
  width: 13px;
  height: 13px;
}

.content {
  text-align: center;
  letter-spacing: -0.75px;
  color: #4a4a4a;
  font-size: 1.3rem;
  line-height: 1.4rem;
}
.content-name {
  text-align: center;
  letter-spacing: -0.75px;
  color: #4a4a4a;
  font-size: 1.1rem;
  line-height: 1.4rem;
}

.open-quotes {
  padding-top: 30px;
  font-size: 7.5rem;
  color: #ffc625;
  line-height: 1rem;
}

.close-quotes {
  font-size: 7.5rem;
  color: #ffc625;
  line-height: 1rem;
  -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
  -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
  -o-transform: matrix(-1, 0, 0, 1, 0, 0);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

@media screen and (max-width: 600px) {
  .card-main {
    padding: 50px 10px;
  }

  .card-0 {
    min-height: 100px;
  }

  .open-quotes {
    margin-left: 250px;
  }

  .close-quotes {
    margin-right: 250px;
  }
}

.carousel-caption {
  position: static;
  height: 250px;
}
