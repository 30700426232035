.benefit-section {
  width: 100%;
  grid-area: top-right;
  display: grid;
  grid-template-columns: 20% auto;
  grid-template-areas: "benefit-right benefit-left";
  padding-bottom: 40px;
}

.benefit-right {
  padding-top: 10px;
  grid-area: benefit-right;
  justify-self: center;
  /* align-items: center; */
}

.benefit-left {
  grid-area: benefit-left;
}

.benefit-title {
  font-size: 1.575rem;
  letter-spacing: -0.9px;
  color: rgb(54, 54, 54);
  padding: 0;
  margin: 0;
}

.applicheck-logo {
  padding-right: 10px;
}

@media screen and (max-width: 600px) {
  .benefit-section {
    padding-bottom: 20px;
  }
  .benefit-title {
    font-size: 1.3rem;
  }
  .main-p {
    font-size: 1rem;
    line-height: 0.8rem;
  }
}
