.bg {
  background-color: rgb(252, 252, 252);
}

.playerSection {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player {
  position: relative;
  width: 800px;
  height: 420px;
  background: rgb(247, 247, 247);
  border: 0.3px solid rgb(112, 112, 112, 0.5);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 33% 33% 33%;
  grid-template-areas: "none" "center" "end";
}
.playIcon {
  grid-area: center;
  justify-self: center;
  align-self: center;
}
.playerTitle {
  grid-area: end;
  align-self: end;
  padding-right: 2%;
}

.playerTitle > h2 {
  letter-spacing: -2.1px;
  color: rgb(216, 216, 216);
  font-size: 3rem;
  line-height: 4.675rem;
}

@media screen and (max-width: 600px) {
  .playerSection {
    /* padding-top: 80px; */
    height: 100%;
  }
  .player {
    height: 200px;
  }
  .playIcon {
    height: 50px;
  }
  .playerTitle > h2 {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
}

/**  BENEFITS **/
.benefits {
  display: grid;
  align-items: end;
  grid-template-columns: 60% 40%;
  grid-template-areas: "right left";
  overflow-x: hidden;
}

.separate {
  min-width: 15.8px;
  height: 71.5px;
  background-color: rgb(255, 198, 37);
  margin-right: 25px;
  margin-left: 25px;
}
.bgImage {
  grid-area: left;
}
.features {
  overflow-x: hidden;
}

.right {
  grid-area: right;
  margin-top: 5%;
  display: grid;
  padding-right: 5%;
  /* grid-template-rows: 50% 50%; */
  overflow-x: hidden;
  grid-template-areas: "top-right" "bottom-right";
}

.first-benefits {
  grid-area: top-right;
  padding-right: 10%;
  overflow-x: hidden;
}

.second-benefits {
  grid-area: bottom-right;
  padding-right: 10%;
  padding-top: 10%;
}

@media screen and (max-width: 1200px) {
  .right {
    grid-template-rows: 40vh;
  }
  .first-benefits {
    padding-right: 10px;
  }
  .second-benefits {
    padding-right: 10px;
  }
}

@media screen and (max-width: 1120px) {
  .first-benefits {
    max-width: 500px;
  }
  .pb-4 {
    padding-bottom: 10px !important;
  }
  .mb-5 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 990px) {
  .first-benefits {
    max-width: 400px;
  }
}

.text-wrapper {
  display: flex;
  padding-right: 10%;
}

.bg-image {
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
}

.bg-cover {
  height: 100vh;
}

@media screen and (max-width: 600px) {
  .benefits {
    display: grid;
    grid-template-columns: 100%;
    grid-template-areas: "right ";
    padding: 15px;
    /* margin-top: 150px; */
  }
  .right {
    align-self: center;
    grid-template-rows: auto auto;
  }
  .first-benefits,
  .second-benefits {
    padding: 0;
    padding-bottom: 20px;
  }
  .display-4 {
    font-size: 1.7rem;
  }
  .text-wrapper {
    padding: 0;
  }
  .row {
    margin: 0;
  }
  .bg-image {
    margin-left: -15px;
  }
}
