/** Fonts **/

@font-face {
  font-family: "OscarFM";
  src: url("./assets/fonts/oscar-regular-fm.otf");
}
@font-face {
  font-family: "Almoni-regular";
  src: url("./assets/fonts/almoni-dl-aaa-regular.otf");
}
@font-face {
  font-family: "Product-Sans";
  src: url("./assets/fonts/Product\ Sans\ Bold.ttf");
}

* {
  font-family: "Almoni-regular";
  text-align: left;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: ltr;
}
a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
a:hover {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}
ol {
  padding: 0;
}

p {
  font-size: 1.375rem;
}
strong {
  font-size: 2.9rem;
}
h2 {
  font-family: "OscarFM";
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

/** Headlines **/
.display-5 {
  letter-spacing: -0.5px;
  color: #808080;
  font-size: 2.1875rem;
}
.display-4 {
  letter-spacing: -0.5px;
  color: #363636;
  font-size: 2.1875rem;
}
.display-3 {
  letter-spacing: -0.5px;
  color: #363636;
  font-size: 1.875rem;
  margin: 0;
}
.display-2 {
  letter-spacing: -0.5px;
  color: #808080;
  font-size: 2.5rem;
  margin: 0;
}
.brand-font {
  font-family: "Product-Sans";
  font-size: 2.1875rem;
  letter-spacing: -0.5px;
}
.hero-p {
  letter-spacing: -0.75px;
  color: #4a4a4a;
  line-height: 1.5625rem;
  font-size: 1.2rem;
}

/** Backgrounds **/
.gray-bg {
  background: #fcfcfc;
}

.orange-bg {
  background: linear-gradient(45deg, rgb(255, 198, 37), rgb(250, 217, 123));
}

.bg-white {
  background-color: #fff;
}

/** Hero Section **/

#hero {
  position: relative;
  width: 100%;
}
#hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  border-radius: 0 0 70% 70% / 0 0 70% 70%;
  background: linear-gradient(
    rgba(237, 237, 237, 0.5),
    rgba(244, 244, 244, 0.5)
  );
  /* transform: scaleX(1.1); */
}

@media screen and (max-width: 600px) {
  #hero::before {
    height: 420px;
    border-radius: 0 0 0 0;
  }
  .CookieConsent {
    background-color: red o !important;
  }
}

/** Main Section **/

.main-p {
  letter-spacing: -0.75px;
  color: #4a4a4a;
  line-height: 1.2625rem;
  font-size: 1.2rem;
}

/** Contact us Section **/

.inp {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #d8d8d8;
  outline: none;
  width: 30%;
  background-color: transparent;
  padding: 0 0 10px 0;
}
.inp-form {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #d8d8d8;
  outline: none;
  width: 100%;
  background-color: transparent;
  padding: 0 0 0 0;
  line-height: 20px;
}

.error {
  /* /border-radius: 5px;/ */
  outline: none;
  width: 100%;
  background-color: transparent;
  border-bottom: 2px solid red !important;
  line-height: 20px;
}

/* .input-form .error::placeholder {
  color: red;
} */

.error::placeholder {
  color: red;
  letter-spacing: -0.5px;
}

.error-msg {
  position: absolute;
  color: red;
}

.inp::placeholder {
  color: #d8d8d8;
  font-size: 20px;
  letter-spacing: -0.5px;
}

.inp:focus {
  border-bottom: 1px solid #ffc625;
  outline: none;
}
.inp-form:focus {
  border-bottom: 1px solid #ffc625;
  outline: none;
}
input[type="text"],
input[type="email"] {
  color: #ffc625;
}

input[type="submit"] {
  background-color: #ffc625;
  font-family: "OscarFM";
  font-size: 1.3rem;
  transition: all ease 0.4s;
}

input[type="submit"]:hover {
  background-color: #ffc625;
  color: black;
  cursor: pointer;
}

/** Cookies **/

.cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 89px;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #ecf0f1;
  background: #fff;
  box-shadow: 0 -3px 30px #0000000d;
}

.cookiealert p {
  font-size: 1.55rem;
  color: #959595;
}

.cookiealert.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.acceptcookies {
  background-color: transparent;
  border: 3px solid #000;
  border-radius: 10px;
  width: 140px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  letter-spacing: -0.6px;
  cursor: pointer;
  transition: 0.5s;
}

.acceptcookies:hover {
  background-color: #000;
  color: #fff;
}

.navbar {
  height: 90px;
}

.submit-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit-btn {
  width: 300px;
  height: 54px;
  border-radius: 10px;
  border: 1px solid #fff;
  color: #fff;
  transition: all ease 0.5s;
  text-align: center;
}

.btn-active {
  font-size: 0px !important;
  width: 50px;
  height: 50px;
  border: 2px solid #ffc625;

  border-radius: 50%;
  border-left-color: transparent;
  animation: rotate 1.4s ease 0.5s infinite;
  background-color: transparent !important;
}

@media screen and (max-width: 600px) {
  .submit-btn {
    background-color: red;
    border-radius: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(360deg);
  }
}
.success {
  position: relative;
  background-color: #ffc625 !important;
  animation: bounce 0.3s ease-in;
  background: url(./assets/img/check.svg) no-repeat;
  background-size: 100% 35px;
}

@keyframes bounce {
  0% {
    transform: scale(0.9);
  }
}
