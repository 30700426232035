p {
  font-size: 13px;
  letter-spacing: -0.4px;
  color: rgb(54, 54, 54);
}

.registration-form-container {
  position: fixed;
  z-index: 999;
  right: 7.5%;
  top: 120px;
  overflow: hidden;
}

.phone-info {
  display: flex;
  flex-direction: column;
}
.phone-info > p {
  color: #d8d8d8;
  font-size: 1.5625rem;
  text-align: left;
  letter-spacing: -0.75px;
  padding: 0;
  margin: 0;
}
.phone-info h2 {
  color: #000000;
  text-align: left;
  letter-spacing: -0.75px;
  padding: 0;
}
.phone-info span {
  align-items: center;
  padding-left: 20px;
}

.form-design {
  height: 570px;
  width: 350px;
  padding: 30px;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 0 60px rgb(0 0 0 / 3%);
}

.form-display-3 {
  letter-spacing: -0.5px;
  color: #363636;
  font-size: 1.875rem;
  padding-top: 20px;
  margin: 0;
}

@media screen and (max-width: 1050px) {
  .form-design {
    width: 300px;
    height: 450px;
    overflow: hidden;
  }
  .form-display-3 {
    letter-spacing: -0.5px;
    color: #363636;
    font-size: 1.875rem;
    margin: 0;
  }
}

.abs {
  position: absolute;
  top: auto;
  bottom: 50px;
  z-index: 999;
}

.orange-line {
  width: 100%;
  height: 5px;
  margin-top: 10px;
  background-color: #ffc625;
}
.form-display-none-md {
  display: none;
}

@media screen and (max-width: 990px) {
  .registration-form-container {
    position: static;
    overflow: hidden;
    width: 100%;
  }
  .registration-form-container::before {
    content: "";
    position: absolute;
    border-radius: 0 0 50% 50%;
    transform: scaleX(2);
    background: linear-gradient(#ffffff, #ebebeb);
    /* -webkit-transform: scaleY(1.5); */
    /* -webkit-transform-origin: 0 0; */
    height: 80%;
    width: 100%;
  }
  .form-design {
    margin-top: 100px;
    border-radius: 28px 28px 0 0;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    height: 550px;
    position: relative;
    overflow: hidden;
  }
  input[type="submit"] {
    border: none;
  }
  .submit-btn {
    border-radius: 0;
    box-shadow: 6px 6px 0 #e87808;
  }
  .phone-info {
    padding-top: 15px;
  }
  .phone-info p {
    color: #000;
    font-weight: 800;
  }
  .form-display-none-sm {
    display: none;
  }
  .form-display-none-md {
    display: block;
  }
}
