#lobby {
  position: relative;
  width: 100%;
  z-index: 1;
}
#lobby::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 500px;
  border-radius: 0 0 70% 70% / 0 0 70% 70%;
  background: linear-gradient(
    rgba(237, 237, 237, 0.5),
    rgba(244, 244, 244, 0.5)
  );
  /* transform: scaleX(1.1); */
}
#lobby h2 {
  color:#000;
  margin-top: 5rem;
  margin-bottom: 0;
}
.lobby-image {
  position: absolute;
  width: 28rem;
  left: 5.6rem;
  top: 8.2rem;
  z-index: -1;
}
.lobby-p {
  letter-spacing: -0.75px;
  color: #4a4a4a;
  line-height: 2.5rem;
  font-size: 1.5425rem;
  margin-bottom: 0;
}
.screen1-image {
  margin-right: 22.6%;
  width: 66%;
}
.screen2-image {
  width: 76%;
  margin-right: 28%;
  margin-top: -10%;
}
.lobby-image img, .screen1-image img, .screen2-image img {
  max-width: 100%;
}
.lobby-bottom {
  display: flex;
}
.lobby-bottom img {
  width: 11%;
  margin-left: 14%;
}
.lobby-ul {
  width: 50%;
}
.lobby-ul ul {
  padding: 0;
}
.lobby-ul li {
  letter-spacing: -0.75px;
  color: #4a4a4a;
  line-height: 2.5rem;
  font-size: 1.5425rem;
}
.lobby-ul li::marker {
  color: #ffc625;
}
@media screen and (max-width: 1000px) {
  .lobby-image {
    width: 68%;
  }
  .screen1-image {
    margin-top: 2rem;
    width: 100%;
    margin-right: 0;
  } 
  .screen2-image {
    margin-top: 2rem;
    width: 100%;
    margin-right: 0;
  } 
}